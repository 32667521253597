/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://unsplash.com/ko/%EC%82%AC%EC%A7%84/oRKF_ZBJYGM"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.apple.com/homepod/"
  }, "Apple HomePod 2세대")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2023/1/19/23562947/twitter-third-party-client-tweetbot-twitterific-ban-rules"
  }, "Twitter officially bans third-party clients with new developer rules")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2023/2/8/23591755/twitter-outage-down-rate-limit-dm-tweetdeck"
  }, "Most people can tweet again, but Twitter still has issues")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.digitalinformationworld.com/2023/02/twitter-allows-its-for-you-and.html"
  }, "Twitter Allows Its ‘For You’ And ‘Following’ Tab To Default To User’s Preference")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.hani.co.kr/arti/economy/economy_general/1078779.html"
  }, "애플페이, 빠르면 다음달 초부터 결제")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Contactless_smart_card"
  }, "Contactless smart card")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.fsc.go.kr/no010101/76100?srchCtgry=&curPage=&srchKey=&srchText=&srchBeginDt=&srchEndDt="
  }, "6.17일부터 어카운트인포 홈페이지에서도 카드포인트 현금화 서비스 이용이 가능해집니다")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.blog/changelog/2023-02-08-pull-request-merge-queue-public-beta/"
  }, "Pull request merge queue (public beta)")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.blog/changelog/2022-09-01-github-actions-larger-runners-are-now-in-public-beta/"
  }, "GitHub Actions Larger runners – Are now in public beta")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://githubnext.com/"
  }, "GitHub Next")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blocks.githubnext.com/"
  }, "GitHub Blocks")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.deepl.com/"
  }, "DeepL")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/tpu?hl=ko"
  }, "Google TPU")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
